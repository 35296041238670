* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_map_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner_map_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.main_map_search_input_and_button_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main_map_search_input_and_button_div div {
    width: 100%;
}

.main_map_search_input_and_button_div div input {
    width: 100%;
    border: none;
    padding: .5rem .5rem;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 100px;
}

.map {
    width: 700px !important;
    height: 600px !important;
}