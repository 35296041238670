* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_dashboard_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 2rem;
}

.inner_dashboard_header_div {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.main_dashboard_header_user_profile_div {
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.inner_dashboard_header_user_img_div {
    width: 50%;
}

.inner_dashboard_header_user_img_div img {
    width: 100%;
}

.inner_dashboard_header_user_details_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.inner_dashboard_header_user_details_div1 {
    font-size: 1.2rem;
    color: #1591C7;
}

.inner_dashboard_header_user_details_div2 {
    font-size: 1.1rem;
    font-weight: 600;
}

.inner_dashboard_header_user_details_div3 {
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: .5rem;
}

.inner_dashboard_header_user_details_div3 svg {
    color: #1591C7;
}

.dashboard_header_order_now_div a {
    width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgb(21, 51, 115);
    background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%);
    padding: 0 1rem;
    border-radius: 10px;
}

.dashboard_header_order_now_div a div {
    font-size: 1.5rem;
    font-weight: bolder;
    color: white;
}

.dashboard_header_order_now_div a img {
    width: 40%;
}

.inner_dashboard_slider_div {
    width: 95%;
}

.slick-next:before,
.slick-prev:before {
    color: black;
    font-size: 2rem;
}

.inner_banner_image_div {
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inner_banner_image_div:focus {
    outline: none;
}

.inner_banner_image_div img {
    width: 95% !important;
}

.inner_dashboard_cards_tabs_div {
    width: 100%;
}

.inner_dashboard_cards_tabs_div .react-tabs__tab-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.inner_dashboard_cards_tabs_div .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
}

.inner_dashboard_cards_tabs_div .react-tabs__tab {
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px !important;
    background-color: white;
}

.inner_dashboard_cards_tabs_div .react-tabs__tab-panel {
    padding: 2rem 0;
}

.inner_dashboard_cards_tabs_div .react-tabs__tab--selected {
    border: 2px solid #FCCC2E !important;
}

.main_dashboard_card_div {
    width: 12rem;
    height: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.main_dashboard_card_inner_div1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1rem;
}

.main_dashboard_card_inner_div1_heading {
    font-size: 1rem;
    position: absolute;
    top: 2%;
    left: 2%;
}

.main_dashboard_card_inner_div1_text {
    font-size: 1.1rem;
    font-weight: bolder;
    color: #1591C7;
    position: absolute;
    bottom: 20%;
    left: 2%;
}

.main_dashboard_card_inner_div2 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.main_dashboard_card_inner_div2 img {
    width: 80%;
}






/* Your Order CSS Start Here */

.main_order_status_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px;
    background-color: white;
}

.main_order_status_back_arrow_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.main_order_status_back_arrow_div svg {
    font-size: 1.5rem;
    cursor: pointer;
    color: #1591C7;
}

.inner_header_order_status_div {
    width: 100%;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inner_header_order_status_div2 {
    width: 15%;
}

.inner_header_order_status_div2 img {
    width: 100%;
}

.inner_body_order_status_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.inner_body_order_loaction_div {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inner_body_order_loaction_div1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .8rem;
}

.inner_body_order_loaction_div1 div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.main_detail_button_holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.main_detail_button_holder button {
    width: 150px;
}

.main_dashboard_modal_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
}

.inner_dashboard_modal_icon_div {
    padding: 2rem;
    background-color: #FCCC2E;
    border-radius: 100px;
}

.inner_dashboard_modal_icon_div svg {
    font-size: 4rem;
    color: white;
}

.inner_dashboard_modal_text_div {
    font-size: 2rem;
    font-weight: 600;
}

/* unsubscribed cylinder CSS Start Here */

.main_unsubscribe_cylinders_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1rem;
}

.main_heading_unsubscribe_cylinders_div {
    font-size: 2rem;
    font-weight: bold;
}

.inner_unsubscribe_cylinders_div {
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px lightgray;
    padding: 2rem 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.heading_unsubscribe_cylinders_div {
    font-size: 1.5rem;
    font-weight: 600;
    color: #4673b6;
}

.text_unsubscribe_cylinders_div {
    font-size: 1.1rem;
}

.button_holder_unsubscribe_cylinders_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-top: 1rem;
}

.button_holder_unsubscribe_cylinders_div button {
    width: 150px;
    border: none;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background-color: transparent;
}

.main_quantity_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inner_quantity_heading_div {
    font-size: 1.6rem;
    font-weight: 600;
}

.main_show_selectaddress_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.inner_show_selectaddress_div1 {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

.inner_show_selectaddress_div1 div svg {
    font-size: 1.5rem;
}

.inner_show_selectaddress_div2 {
    width: 20%;
    text-align: center;
    color: red;
    cursor: pointer;
}

.inner_show_selectaddress_div2 a {
    text-decoration: none;
    color: red;
}

.inner_show_selectaddress_div2 a:hover,
.inner_show_selectaddress_div2 a:focus,
.inner_show_selectaddress_div2 a:after {
    text-decoration: none;
    color: red;
}

.main_send_request_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_send_request_div button {
    width: 400px;
    padding: .7rem 0;
    background: rgb(21, 51, 115);
    background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%);
    border: none;
    border-radius: 100px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}





/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {
    .inner_dashboard_cards_tabs_div .react-tabs__tab-list {
        justify-content: center;
        gap: 3rem;
    }

    .main_dashboard_card_div {
        width: 10rem;
    }

    .dashboard_header_order_now_div {
        width: 11rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dashboard_header_order_now_div a div {
        font-size: 1rem;
    }

    .main_dashboard_header_user_profile_div {
        width: 52%;
    }

    .inner_banner_image_div img {
        width: 100% !important;
        height: 100px;
    }
}

/* Ipad Media Query End Here */


/* Phone Media Query Start Here */

@media only screen and (max-width: 400px) {
    .inner_dashboard_header_div {
        height: fit-content;
        flex-direction: column;
        gap: 2rem;
    }

    .main_dashboard_header_user_profile_div {
        width: 100%;
    }

    .inner_dashboard_cards_tabs_div .react-tabs__tab-list {
        gap: 1rem;
    }

    .main_dashboard_card_div {
        width: 6rem;
    }
}

/* Phone Media Query End Here */