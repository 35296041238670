* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.wallet_amount_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

.inner_wallet_amount_div1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: 0rem 1rem;
}

.inner_wallet_amount_div1_username {
    font-size: 2rem;
    font-weight: bold;
}

.inner_wallet_amount_div1_wallet_balance {
    font-size: 1.5rem;
    font-weight: 600;
}

.inner_wallet_amount_div1_wallet_amount {
    font-size: 1.2rem;
    font-weight: 600;
    color: rgb(21, 51, 115);
}

.inner_wallet_amount_div2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

/* .wallet_amount_div div {
    width: 60%;
    background: rgb(21, 51, 115);
    background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 0;
    border-radius: 10px;
    box-shadow: 0px 0px 5px lightgray;
    font-size: 1.4rem;
    font-weight: bold;
} */

.wallet_amount_date_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1rem;
}

.wallet_amount_date_div div {
    font-size: 1.3rem;
    font-weight: bold;
}

.wallet_amount_date_div hr {
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin: 0%;
}



/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {
    .wallet_amount_div div {
        width: 80%;
    }
}

/* Ipad Media Query End Here */


/* Phone Media Query Start Here */

@media only screen and (max-width: 400px) {
    .wallet_amount_div div {
        width: 95%;
    }
}

/* Phone Media Query End Here */