@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0%;
    padding: 0%;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 280px;
    background-image: url(../../assets/sidenav.png);
    /* background: rgb(21, 51, 115); */
    /* background: linear-gradient(360deg, rgba(104, 169, 217, 1) 0%, rgba(21, 51, 115, 1) 100%); */
    box-shadow: 0px 0px 5px 0px lightgray;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 3;
    transition: all 0.5s ease;
}

.sidebar.close {
    width: 60px;
}

.sidebar .logo-details {
    width: 100%;
    height: 9%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.sidebar .logo-details i {
    font-size: 30px;
    color: gray;
    height: 50px;
    min-width: 60px;
    text-align: center;
    line-height: 50px;
}

.logo-details i img {
    width: 70%;
}

.logo-details span {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-details span img {
    width: 65%;
    margin-top: 15%;
}

.sidebar .logo-details .logo_name {
    font-size: 22px;
    color: gray;
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
}

.sidebar.close .logo-details .logo_name {
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links {
    height: 100%;
    padding: 30px 0 150px 0;
    overflow: auto;
}

.sidebar.close .nav-links {
    overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
    display: none;
}

.sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}

.sidebar .nav-links>li.active:before,
.sidebar .nav-links>li:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 4px;
    height: 100%;
    background: #FCCC2E;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    border-top-right-radius: 5px;
    border-top-right-radius: 5px;
}

.sidebar .nav-links li.active:before,
.sidebar .nav-links li:hover:before {
    opacity: 1;
}

.sidebar .nav-links li .iocn-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar.close .nav-links li .iocn-link {
    display: block
}

.sidebar .nav-links li i {
    height: 40px;
    min-width: 60px;
    text-align: center;
    line-height: 40px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar .nav-links li.active i,
.sidebar .nav-links li:hover i {
    color: #FCCC2E;
}

.sidebar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
}

.sidebar.close .nav-links i.arrow {
    display: none;
}

.sidebar .nav-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sidebar .nav-links li a .link_name {
    font-size: 16px;
    font-weight: 400;
    color: white;
    transition: all 0.4s ease;
}

.sidebar .nav-links li.active a .link_name,
.sidebar .nav-links li:hover a .link_name {
    color: #FCCC2E;
}

.sidebar.close .nav-links li a .link_name {
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
    padding: 6px 6px 14px 40px;
    margin-top: -10px;
    display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
    display: block;
}

.sidebar .nav-links li .sub-menu a {
    border: none !important;
    color: white;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.8;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;
}

.sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
    background-color: #FAFAFA;
}

.sidebar.close .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    background: rgb(21, 51, 115) !important;
    background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%);
}

.sidebar .nav-links li .sub-menu .link_name {
    display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
    font-size: 18px;
    opacity: 1;
    display: block;
}

.sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
    background-color: #FAFAFA;
}

.sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
}

.sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding: 12px 0;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details {
    background: none;
}

.sidebar.close .profile-details {
    width: 60px;
}

.sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
}

.sidebar .profile-details img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
    color: white;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 5s ease;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
    display: none;
}

.sidebar .profile-details .job {
    font-size: 12px;
}

.home-section {
    position: relative;
    background: white;
    background-image: url(../../assets/background-img.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    left: 280px;
    width: calc(100% - 280px);
    transition: all 0.5s ease;
    overflow-y: auto;
}

.sidebar.close~.home-section {
    left: 60px;
    width: calc(100% - 60px);
}

.home-section .home-content {
    right: 0rem;
    left: 280px;
    width: calc(100% - 280px);
    padding-left: 50px;
    padding-right: 1rem;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FCCC2E;
    box-shadow: 0px 0px 5px 0px lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    z-index: 2;
    transition: all 0.5s ease;
}

.sidebar.close~.home-section .home-content {
    left: 60px !important;
    width: calc(100% - 60px) !important;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
    color: gray;
    font-size: 35px;
}

.home-section .home-content .bx-menu {
    margin: 0 15px;
    cursor: pointer;
}

.home-section .home-content .text {
    font-size: 26px;
    font-weight: 600;
}

.toggleSidebar {
    position: absolute;
    top: 2%;
    right: -40px;
    z-index: 1;
}

.toggleSidebar i {
    font-size: 30px;
    color: white;
}

.toggleSidebarclose {
    position: absolute;
    top: 7%;
    right: -13px;
    z-index: 1;
    font-size: 1.5rem;
    color: #FCCC2E;
    /* opacity: 5 !important; */
}

.toggleSidebarclose svg {
    cursor: pointer;
}


.main_section_holder_div {
    width: 100%;
    /* height: 90.8%; */
    position: relative;
    top: 60px;
    padding: 2rem;
}



/* Phone Media Query Start Here */

@media only screen and (max-width: 400px) {
    .home-section {
        left: 60px;
        width: calc(100% - 60px);
    }

    .main_section_holder_div {
        padding: 1rem;
    }

    .header_location_heading_div {
        font-size: .7rem !important;
    }

    .header_location_div {
        font-size: 0.5rem !important;
    }

    .home-section .home-content {
        padding: 10px;
    }

    .main_header_user_and_icon_holder {
        gap: 5px;
    }

    .header_user_name {
        font-size: .8rem;
    }

    .home-section .home-content {
        left: 60px !important;
        width: calc(100% - 60px) !important;
    }

    .sidebar.close {
        box-shadow: 0px 0px 0px #00000082;
    }

    .sidebar {
        box-shadow: 166px 0px 0px #00000082;
    }
}

/* Phone Media Query End Here */

/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {
    .home-section .home-content {
        padding-left: 15px;
    }

    .header_location_heading_div {
        font-size: 1rem;
    }

    .header_location_div {
        font-size: .8rem;
    }
}

/* Ipad Media Query End Here */