* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_manage_address_div {
    width: 100%;
    padding: 1rem;
    background-color: white;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.main_add_address_section_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.inner_add_address_section_div {
    background-color: white;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 100%;
    cursor: pointer;
}

.inner_add_address_section_div svg {
    padding: .5rem .8rem;
    font-size: 3rem;
    color: #1591C7;
}

.main_manage_address_card_div {
    width: 100%;
    padding: .5rem 2rem;
    background-color: white;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    gap: .5rem;
    cursor: pointer;
}

.main_manage_address_card_header_div {
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.main_manage_address_card_header_heading_div {
    font-size: 1.3rem;
    font-weight: bold;
}

.main_manage_address_card_header_edit_div {
    background-color: #1591C7;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 100%;
    cursor: pointer;
}

.main_manage_address_card_header_edit_div svg {
    padding: .2rem .4rem;
    font-size: 2.2rem;
    color: white;
}

.main_manage_address_card_body_div {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: .3rem;
    color: gray;
}

.main_add_address_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    /* padding: 1rem; */
    /* box-shadow: 0px 0px 5px lightgray; */
    /* border-radius: 10px; */
    /* background-color: white; */
}

.main_add_address_back_arrow_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 2rem;
}

.main_add_address_back_arrow_div span {
    font-size: 1.8rem;
    font-weight: bold;
}

.main_add_address_back_arrow_div svg {
    font-size: 2rem;
    cursor: pointer;
    color: #1591C7;
}

.inner_header_add_address_div {
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.main_add_address_save_button_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_add_address_save_button_div button {
    width: 80%;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 20px;
    border: none;
    color: white;
    background: rgb(21, 51, 115);
    background: linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%);
    padding: .7rem 0;
    margin-top: 1rem;
}

.select_address_div {
    width: 100%;
    position: relative;
}

.select_address_div svg {
    position: absolute;
    right: 0;
    font-size: 2.5rem;
    transform: rotate(60deg);
    z-index: 1;
}