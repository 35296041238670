* {
    margin: 0%;
    padding: 0%;
}

body {
    margin: 0%;
    padding: 0%;
}

.main_order_list_div {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.main_order_list_tabs_section_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 2rem;
}

.order_list_tab_active {
    background-color: #0A74B9 !important;
}

.order_list_tab_active a {
    color: white !important;
}

.main_order_list_tabs_section_div div {
    background-color: lightgray;
    padding: .3rem 1rem;
    border-radius: 5px;
}

.main_order_list_tabs_section_div div a {
    text-decoration: none;
    color: gray;
    font-weight: 600;
}

.main_order_list_cards_div {
    width: 100%;
    padding: 2rem 1rem;
    background-color: white;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.main_order_list_cards_inner_div {
    width: 100%;
    padding: .5rem 2rem;
    background-color: white;
    box-shadow: 0px 0px 5px lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.order_list_card_inner_div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .3rem;
}

.order_list_card_inner_div img {
    width: 4rem;
}

.order_list_card_inner_heading_div {
    font-size: 1.1rem;
    font-weight: bold;
}

.order_list_card_inner_value_div {
    font-size: .9rem;
    color: gray;
}


/* new CSS start here */
.main_order_list_cards_div .react-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.main_order_list_cards_div .react-tabs__tab-list {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    box-shadow: 0px 0px 5px gray;
    border-radius: 100px;
    overflow: hidden;
}


.main_order_list_cards_div .react-tabs__tab {
    width: 130px;
    text-align: center;
    padding: .5rem 0;
    bottom: 0;
    position: static;
}

.main_order_list_cards_div .react-tabs__tab--selected {
    background-color: #0A74B9;
    color: white;
    border-radius: 0;
}

.main_order_list_cards_div .react-tabs__tab-panel--selected {
    width: 100%;
}

.main_scheduled_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.main_scheduled_div button {
    width: 120px;
    padding: .5rem 0;
    border: none;
    border-radius: 100px;
    color: white;
    font-weight: 600;
}




/* Ipad Media Query Start Here */

@media only screen and (max-width: 850px) {
    .main_order_list_tabs_section_div {
        gap: 1rem;
    }

    .main_order_list_cards_inner_div {
        flex-wrap: wrap;
        gap: 1rem;
        padding: .5rem 1rem;
    }
}

/* Ipad Media Query End Here */


/* Phone Media Query Start Here */

@media only screen and (max-width: 400px) {}

/* Phone Media Query End Here */